import React, { useState } from 'react';
import './instruction.scss';
import { useTranslation } from 'react-i18next';


import listing from "./Images/listing.png"
import Countdown from './Countdown';
import characterGlasses from "./characterGlasses.png"
import photo1 from "./1.png"


function Instruction(props) {
    const { setShow } = props;
    const { t } = useTranslation();

    const info = [
        {
            id: 1,
            image:photo1,
            title:"Earn coins with every tap!",
            icon: characterGlasses,
            subtext: "Use boosters to multiply your coins!"
        },
        {
            id: 2,
            image:"",
            title:"Turn your bear into a super bear!",
            icon: characterGlasses,
            subtext: "Strengthen your bear and become the next Grandmaster!"
        },{
            id: 3,
            image:"",
            title:"Perfect your skills.",
            icon: characterGlasses,
            subtext: "Maximize the level of your cards for more income."
        },{
            id: 4,
            image:"",
            title:"Invite friends and receive exclusive bonuses!",
            icon: characterGlasses,
            subtext: "Secure valuable coins for you and your friend!"
        },
        {
            id: 5,
            image: "",
            title: "Become part of our communities and meet other players!",
            icon: characterGlasses,
            subtext: ""
        },
        {
            id: 6,
            image: "",
            title: "Use coins and secure an airdrop at the token launch!",
            icon: characterGlasses,
            subtext: "Don't forget to invite your friends! Good luck!"
        },
        {
            id: 7,
            image: "",
            title: "",
            icon: "",
            subtext: ""
        }
    ];
    const [currentPage, setCurrentPage] = useState(0);

    const handleNext = () => {
        if (currentPage === info.length - 1) {
            setShow(false);
        } else {
            setCurrentPage((currentPage + 1) % info.length);
        }
    };

    return (
        <div className='instruction_container'>


                {currentPage == 6 ?
                    <>
                        <div className='flex_instruction'>
                            <img src='https://nikicoin.store/media/newcoin_NIKI/nikicoin00.png'></img>
                            <h1 style={{ textAlign: "center", color: "white" }}>LISTING TIME</h1>
                        </div>
                        <Countdown />

                        <img className='img_listing' src={listing}></img>
                        <div className='start_btn' onClick={() => setShow(false)}>START</div>
                    </>
                    :
                    <>
                    <img className='image_main' src={info[currentPage].image}/>
                    <div className='bottom_instruction'>
                        <div className='bottom_section'>
                            <div className='left_box'>
                                <img src={info[currentPage].icon} alt='Bear with Glasses' />
                            </div>
                            <div className='right_box'>
                                <h2>{info[currentPage].title}</h2>
                                <p>{info[currentPage].subtext}</p>
                            </div>
                        </div>

                        <div className='next_button'>
                            <button onClick={handleNext}>Next</button>
                        </div>
                    </div>
                        
                    </>
                }
        </div>
    );
}

export default Instruction;
