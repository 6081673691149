import React from 'react'
import Footer from '../Footer/Footer'
import Back from '../Back'
import airdrop from "./airdrop.png"
import "./airdrop.scss"
import wallet from "./wallet.png"
import arrow from "./arrow.svg"
function Airdrop() {
  return (
    <div>
      <Back />
      <div className='airdrop_container'>
        <div className='image_container'>
          <img src={airdrop} className="" draggable="false" alt="Coin" />
          <div className='glow_airdrop'></div>
        </div>
        <div className="title">AirDrop tasks</div>
        <div className='subtitle'>Listing is on its way. Tasks will appear below. Complete them to participate in the AirDrop</div>
      </div>
      
      <div className='content'>
        <div className='title'>Tasks list</div>
        <div className='connect_button'>
          <img src={wallet} className='wallet'/>
          <div>
            Connect your TON wallet
          </div>
          <img src={arrow} className='arrow_airdrop'/>
        </div>
      </div>

      <Footer/>
    </div>
  )
}

export default Airdrop