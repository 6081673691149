import React, { useState, useEffect } from 'react';
import "./tasks.scss";
import { getUserTasks } from '../../http';
import Back from '../Back';
import Task from './Task';
import { useAppData } from '../../AppContext';
import { completeTask, seeTask } from '../../http';
import { useTranslation } from 'react-i18next';

import dollar_coin from '../../pages/dollar_coin.png';
function Vidz() {
    const [tasks, setTasks] = useState([]);
    const { t } = useTranslation();
    const [selectedTask, setSelectedTask] = useState(null);
    const [TriggerRender, setTriggerRender] = useState(true);
    const { handleEditCoins} = useAppData();

    const getTasksData = async () => {
        try {
            const data = await getUserTasks("video");
            setTasks(data.tasks);
        } catch (error) {
            console.error('Failed to fetch memes', error);
        }
    };
    function openLink(link) {
        if (window.Telegram.WebApp) {
            window.Telegram.WebApp.openLink(link);
        }
    }
    useEffect(() => {
        getTasksData();
    }, [TriggerRender]);
    const handleCloseModal = () => {
        setSelectedTask(null);
    };

    const handleTaskClick = (task) => {
        setSelectedTask(task);
    };

    const handleMoadalTaskClick = async (link, id, task__coins_reward, status) => {
        try {
            if (status) {
                await completeTask(id);
                handleEditCoins(task__coins_reward);
            } else {
                seeTask(id);
            }
            openLink(link);
            handleCloseModal();
            forceUpdate();
        } catch (error) {
            console.error("Failed to complete task", error);
        }
    };

    const forceUpdate = () => setTriggerRender(prev => !prev);

  return (
    <div className='container_glow_shop'>
        <Back />
        <div className='daily_text'>Daily Videos</div>

        <div className='shop_grid'>
                {tasks.map((task) => (
                    <Task
                        key={task.id}
                        task={task.task__name}
                        icon={task.task__logo || test}
                        coins={`+${task.task__coins_reward}`}
                        status={task.status}
                        onClick={() => handleTaskClick(task)}
                    />
                ))}
            </div>

            {selectedTask && (
                <div className="modal">
                    <div className="modal-container-tasks">
                        <span className="close" onClick={handleCloseModal}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="12" cy="12" r="10" fill="#CACACA" fill-opacity="0.46" />
                                <path d="M16 8L8 16" stroke="#131313" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M8 8L16 16" stroke="#131313" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </span>
                        <div className="modal-content_tasks">
                            <img src={selectedTask.task__logo || test} className="modal_image_tasks" alt="Task icon" />
                            <h2 className='modal-title'>{selectedTask.task__name}</h2>
                            {selectedTask.task__link !== "TG" ? (
                                <button className='button_tasks_join' onClick={() => handleMoadalTaskClick(selectedTask.task__link, selectedTask.id, selectedTask.task__coins_reward, selectedTask.status)}>Join</button>
                            ) : (
                                <> </>
                            )}
                            <div className='coins_container_tasks'>
                                <img src={dollar_coin} className="coin_icon_modal_tasks" alt="Coin-icon" />
                                +{selectedTask.task__coins_reward}
                            </div>

                            
                                {
                                selectedTask.is_seen ?
                                <button className='open_task button_tasks' onClick={() => handleMoadalTaskClick(selectedTask.task__link, selectedTask.id, selectedTask.task__coins_reward, selectedTask.status)}>Take Reward</button>
                                :
                                <button className='open_task button_tasks' onClick={() => handleMoadalTaskClick(selectedTask.task__link, selectedTask.id, selectedTask.task__coins_reward, selectedTask.status)}>{t('taskspage.openTask')}</button>
                                
                                }
                        </div>
                    </div>
                </div>
            )}
    </div>
  )
}

export default Vidz