import React from 'react';
import { useNavigate } from 'react-router-dom';
import "./home.scss";

function Block({ name, link, image }) {

    const navigate = useNavigate();

    return (
        <div onClick={() => navigate(link)} className='daily'>
            <img src={image} alt={name} />
            <name >{name}</name>
        </div>
    );
}

export default Block;
