import React from 'react'
import "./skins.scss"
import locked from "./locked.png"
import checked from "./check.png"
function Skin({skin}) {
  const getIcon = () => {
    if (skin.is_owned) {
      if (skin.is_selected) {
        return checked; 
      }
      return null; 
    } else {
      return locked; 
    }
  };
  


  return (
    <div className='skin'>
        <img className='image_status' src={getIcon()}/>
        <img className='img_icon' src={skin.icon_small}/>
        <div className='name'>{skin.name}</div>
    </div>
   
  )
}

export default Skin