import React, { useState, useEffect, useRef } from 'react';
import { useAppData } from '../../AppContext';
import Back from '../Back';
import Footer from '../Footer/Footer';
import "./leagues.scss";
import Arrow from './back.png';
import { getLeague } from '../../http';
import { formatNumber } from '../../Utils/formatNumber';
import logo from "./logo.png"
import dollar_coin from "../../pages/dollar_coin.png"
export const Leagues = () => {
    const { total_coins_earned, leagues } = useAppData();
    const leagueBar = useRef(null);

    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentLeagueData, setCurrentLeagueData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        fetchData(currentIndex);
    }, [currentIndex, leagues]);

    const fetchData = async (index) => {
        if (index !== null && leagues[index]) {
            setLoading(true);
            setError('');
            try {
                const data = await getLeague('week', leagues[index].name);
                console.log(data)
                setCurrentLeagueData(data);
            } catch (err) {
                console.error('Error fetching league data:', err);
                setError('Failed to fetch league data.');
            }
            setLoading(false);
        }
    };

    const findCurrentLeagueIndex = () => {
        for (let i = leagues.length - 1; i >= 0; i--) {
            if (leagues[i].coin_limit === "max" || total_coins_earned >= leagues[i].coin_limit) {
                return i;
            }
        }
        return 0;
    };

    useEffect(() => {
        setCurrentIndex(findCurrentLeagueIndex());
    }, [total_coins_earned, leagues]);

    const navigateLeague = (direction) => {
        const newIndex = currentIndex + direction;
        if (newIndex >= 0 && newIndex < leagues.length) {
            setCurrentIndex(newIndex);
        }
    };


    useEffect(() => {
        if (leagueBar.current && currentLeagueData) {
            const league = leagues[currentIndex];
            const coin_limit = league.coin_limit === "max" ? total_coins_earned : league.coin_limit;
            const width = coin_limit === "max" ? 100 : (total_coins_earned / coin_limit) * 100;
            if(width < 1 ){
                leagueBar.current.style.width = `${Math.min(1, 100)}%`;
            }
            else{
                leagueBar.current.style.width = `${Math.min(width, 100)}%`;

            }
        }
    }, [total_coins_earned, currentLeagueData]);

    const currentLeague = leagues[currentIndex] || {};
    console.log("currentLeagueData")
    console.log(currentLeagueData?.league.players)
    return (
        <div className='leagueListContainer' >
            {loading && <></>}
            {error && <p>Error: {error}</p>}
            <Back />
            
            <div className="trophy">
                <img src={currentLeague.logo}/>
            </div>
            <div className="leagueText">
                <h1>{currentLeague.name}</h1>
            </div>
            <div className="leagueBar">
                <div className="energy-counter">
                    {currentLeague.coin_limit === "max"
                        ? <span>MAX</span>
                        : <>{formatNumber(total_coins_earned)}/{formatNumber(currentLeague.coin_limit)}</>
                    }
                </div>
                <div className="energy-bar" ref={leagueBar} />
            </div>
            <div className="league-navigation">
                <button onClick={() => navigateLeague(-1)} disabled={currentIndex === 0}>
                    <img src={Arrow} alt="Previous" />
                </button>
                <button onClick={() => navigateLeague(1)} disabled={currentIndex === leagues.length - 1}>
                    <img src={Arrow} alt="Next" />
                </button>
            </div>
            {currentLeagueData && currentLeagueData.league && currentLeagueData.league.players && currentLeagueData.league.players.length > 0 ? (
                currentLeagueData.league.players.map((player, index) => (
                    <div key={player.telegram_id} className='leaguePlayer'>
                        <div className='leagueFlexRow'>
                            <img className='logoLeague' src={logo}/>
                            <div className='playerInfo'>
                                <p>{player.name}</p>
                                <div className='leagueFlexRow'>
                                    <img className='coinLeague' src={dollar_coin}/>
                                    {player.total_earned}
                                </div>
                            </div>
                        </div>
                       
                        <h3>{index + 1}</h3>
                    </div>
                ))
            ) : (
                <p>No data or still loading...</p>
            )}

            <Footer />
        </div>
    );
};
