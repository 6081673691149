import React, { useState, useEffect } from 'react';
import Skin from './Skin';
import './skins.scss';
import Arrow from './back.png';
import { getPlayerCoinSkins, buyCoinSkin, selectCoinSkin } from '../../http';
import Back from '../Back';
import { useAppData } from '../../AppContext'

function Skins() {
  const [skins, setSkins] = useState([]);
  const [selectedSkin, setSelectedSkin] = useState(null); 
  const [currentIndex, setCurrentIndex] = useState(0);
  const {setTriggerRender} = useAppData()

  const getAllSkinstData = async () => {
    try {
      const data = await getPlayerCoinSkins();
      setSkins(data.player_coin_skins);
      setSelectedSkin(data.player_coin_skins[0]);
    } catch (error) {
      console.error('Failed to fetch skins', error);
    }
  };
  useEffect(() => {
    getAllSkinstData();
  }, []);
  const updateSkinsData = async () => {
    try {
      const data = await getPlayerCoinSkins(); 
      setSkins(data.player_coin_skins);
      setSelectedSkin(data.player_coin_skins[currentIndex]);
      setTriggerRender(prev => !prev);

    } catch (error) {
      console.error('Failed to update skins', error);
    }
  };

 

  const skinsArray = Object.values(skins);

  const changeSkin = (index) => {
    setCurrentIndex(index);
    setSelectedSkin(skinsArray[index]);
    setTriggerRender(prev => !prev);

  };

  const handleSkinClick = (index) => {
    changeSkin(index);
  };

  const handleNext = () => {
    if (currentIndex < skinsArray.length - 1) {
      changeSkin(currentIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      changeSkin(currentIndex - 1);
    }
  };

  const handleBuySkin = async (skinId) => {
    try {
      await buyCoinSkin(skinId);
      await updateSkinsData(); 
    } catch (error) {
      console.error('Failed to buy skin', error);
    }
  };

  const handleSelectSkin = async (skinId) => {
    try {
      await selectCoinSkin(skinId);
      await updateSkinsData(); 
    } catch (error) {
      console.error('Failed to select skin', error);
    }
  };

  if (!selectedSkin) {
    return <div>Loading...</div>;
  }

  return (
    <div className="skins_page">
      <Back />
      <div className="selected_skin_container">
        <div className="selected_skin">
          <div className="image_container">
            <img src={selectedSkin.icon_big} className="img_main" alt={selectedSkin.name} />

            <div className="navigation">
              <button onClick={handlePrevious} disabled={currentIndex === 0}>
                <img src={Arrow} alt="Previous" />
              </button>
              <button onClick={handleNext} disabled={currentIndex === skinsArray.length - 1}>
                <img src={Arrow} alt="Next" />
              </button>
            </div>
          </div>

          <div className="selected_skin_infoBlock">
            <div className="selected_skin_name">{selectedSkin.name}</div>
            <div className="selected_skin_description">{selectedSkin.description}</div>

            {selectedSkin.is_owned ? (
              <div className="selected_skin_purchased">Purchased</div>
            ) : (
              <div className="selected_skin_price">{selectedSkin.price}</div>
            )}

            {selectedSkin.league ? (
              <div className="selected_skin_league">{selectedSkin.league}</div>
            ) : null}

            {selectedSkin.available && !selectedSkin.is_owned ? (
              <div className="selected_skin_league" onClick={() => handleBuySkin(selectedSkin.skin_id)}>buy</div>
            ) : null}
            {selectedSkin.is_owned && !selectedSkin.is_selected ? (
              <div className="selected_skin_league" onClick={() => handleSelectSkin(selectedSkin.skin_id)}>select</div>
            ) : null}
          </div>
        </div>
      </div>

      <div className="skins_container">
        {skinsArray.length > 0 ? (
          skinsArray.map((skin, index) => (
            <div key={skin.skin_id} onClick={() => handleSkinClick(index)}>
              <Skin skin={skin} />
            </div>
          ))
        ) : (
          <p>No skins available</p>
        )}
      </div>
    </div>
  );
}

export default Skins;
