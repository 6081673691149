import React, {useState, useEffect} from 'react'
import "./units.scss"
import { getPlayerUnits, selectUnit} from '../../http'
import Back from '../Back';
import Footer from '../Footer/Footer';
import arrow from "./arrow.svg"
import done from "./done.svg"
import { useAppData } from '../../AppContext';
function Units() {

 const [units, setUnits] = useState([]);
 const {setUnit} =useAppData()


 const getAllUnitsData = async () => {
    try {
      const data = await getPlayerUnits();
      setUnits(data.units)
    console.log(data)
    } catch (error) {
      console.error('Failed to fetch skins', error);
    }
  };

  const handleSelect = async (id,icon)=> {
    await selectUnit(id)
    setUnit(icon)
    getAllUnitsData()
  }

  useEffect(() => {
    getAllUnitsData();
  }, []);

  if (!units) {
    return <div>Loading...</div>;
  }
  return (
    <div className='units'>
        <Back/>
        <div className='title_units'>Choose your Unit</div>
        {units.length > 0 ? (
          units.map((unit, index) => (
            <div className='unit' key={unit.skin_id} onClick={unit.is_selected? null:()=>handleSelect(unit.id,unit.unit__logo)} >
            <div className='info_container_units'>
                <img className='icon' src={unit.unit__logo}/>
                <div>{unit.unit__name}</div>
            </div>
              
              {unit.is_selected? 
                <img className='action_icon' src={done}/>
                :
                <img className='action_icon' src={arrow}/>
                }
            </div>
          ))
        ) : (
          <p>No skins available</p>
        )}
        <Footer/>
    </div>
  )
}

export default Units